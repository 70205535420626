import React from 'react';
import Card from './Card';

function CardList({cards}) {
    return(
        <div className='card-grid'>
            {cards.map(card => {
                return <Card portfolioCard={card} key={card.id} />;
            })}
        </div>
    );
};

export default CardList;