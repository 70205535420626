import React from 'react';
import CardList from '../portfolioCards/CardList';
import '../portfolioCards/card.css';

import { portfolioData } from '../../data/portfolioData';

function Portfolio() { 
    return(
        <div>
            <CardList cards={(portfolioData)} />
        </div>
    );
};

export default Portfolio;