export const portfolioData = [
    {
        id: 1,
        img: '',
        name: 'Recipe Generator Frontend',
        description: 'A collaberative group project that allows a user to create an account via Auth0, log in and select a recipe, this returns a shopping list of ingredience & instructions.',
        isLive: false,
        languages: '[![My Skills](https://skillicons.dev/icons?i=js,react,html,tailwind)](https://skillicons.dev)',
        link: 'https://github.com/Illyriat/Recipe_Generator_FrontEnd'
      },
      {
        id: 2,
        img: '',
        name: 'Recipe Generator Backend',
        description: 'A collaberative group project that allows a user to create an account via Auth0, log in and select a recipe, this returns a shopping list of ingredience & instructions.',
        isLive: false,
        languages: '[![My Skills](https://skillicons.dev/icons?i=java,spring)](https://skillicons.dev)',
        link: 'https://github.com/Illyriat/Recipe_Generator_Back_End'
      },
      {
        id: 3,
        img: '',
        name: 'Shalidors Library',
        description: 'A Webapp that displays information & guides about the game The Elder Scrolls Online. An on going work in progress that follows each quaterly update of the game.',
        isLive: false,
        languages: '[![My Skills](https://skillicons.dev/icons?i=js,react,html,css)](https://skillicons.dev)',
        link: 'https://github.com/Illyriat/Shalidors-Library'
      },
      {
        id: 4,
        img: '',
        name: 'ISS Tracker',
        description: 'Tracks the current position of the ISS on a map, and updates that infomation every 5 seconds. Takes the information, creates a file, and saves the file into a downloads folder within the project file structure each time the code is executed.',
        isLive: false,
        languages: '[![My Skills](https://skillicons.dev/icons?i=py)](https://skillicons.dev)',
        link: 'https://github.com/Illyriat/ISS-Tracker'
      },
      {
        id: 5,
        img: '',
        name: 'ToxicChat',
        description: 'An Addon for The Elder Scrolls Online. Download the repo and move into your Addon folder Path for your copy of the game to use and test. Designed to give random messages from an array to the player in the global chat window.',
        isLive: true,
        languages: '[![My Skills](https://skillicons.dev/icons?i=lua)](https://skillicons.dev)',
        link: 'https://github.com/Illyriat/ToxicChat'
      },
      {
        id: 6,
        img: '',
        name: 'Ranckors Baggage',
        description: 'An Addon for The Elder Scrolls Online. Download the repo and move into your Addon folder Path for your copy of the game to use and test. Shows different types of currencies the player has as part of a UI addition.',
        isLive: true,
        languages: '[![My Skills](https://skillicons.dev/icons?i=lua)](https://skillicons.dev)',
        link: 'https://github.com/Illyriat/RanckorsBaggage'
      }
];