import React, { useState, useEffect } from 'react';
import './miniGameStyle/TicTacToe.css';

const PLAYER = 'X';
const COMPUTER = 'O';

function TicTacToePVE() {
  const [board, setBoard] = useState(Array(9).fill(null));
  const [xIsNext, setXIsNext] = useState(true);
  const [isComputersTurn, setIsComputersTurn] = useState(false);
  const [playerWins, setPlayerWins] = useState(0);
  const [computerWins, setComputerWins] = useState(0);
  const [draws, setDraws] = useState(0);

  useEffect(() => {
    if (!xIsNext && isComputersTurn && !calculateWinner(board)) {
      const timer = setTimeout(() => {
        const newBoard = [...board];
        const computerMove = getRandomAvailablePosition(newBoard);
        newBoard[computerMove] = COMPUTER;
        setBoard(newBoard);
        setXIsNext(true);
        setIsComputersTurn(false);
      }, 1000); // Delay the computers turn

      return () => clearTimeout(timer); // Clear the timeout on component unmount
    }
  }, [xIsNext, isComputersTurn, board]);

  const handleClick = (index) => {
    if (board[index] || calculateWinner(board) || !xIsNext) {
      return;
    }

    const newBoard = [...board];
    newBoard[index] = PLAYER;
    setBoard(newBoard);
    setXIsNext(false);
    setIsComputersTurn(true);
  };

  const handleReset = () => {
    setBoard(Array(9).fill(null));
    setXIsNext(true);
    setIsComputersTurn(false);
    setPlayerWins(0);
    setComputerWins(0);
    setDraws(0);
  };

  const handleNewGame = () => {
    setBoard(Array(9).fill(null));
    setXIsNext(true);
    setIsComputersTurn(false);
  };

  useEffect(() => {
    const winner = calculateWinner(board);
    if (winner === PLAYER) {
      setPlayerWins(playerWins + 1);
    } else if (winner === COMPUTER) {
      setComputerWins(computerWins + 1);
    } else if (board.every((square) => square !== null)) {
      setDraws(draws + 1);
    }
  }, [board]);

  const calculateWinner = (squares) => {
    const lines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];
  
    for (let i = 0; i < lines.length; i++) {
      const [a, b, c] = lines[i];
      if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
        return squares[a];
      }
    }
  
    return null;
  };

  const getRandomAvailablePosition = (board) => {
    const availablePositions = [];
    for (let i = 0; i < board.length; i++) {
      if (!board[i]) {
        availablePositions.push(i);
      }
    }
    return availablePositions[Math.floor(Math.random() * availablePositions.length)];
  };

  const renderSquare = (index) => {
    return (
      <button className="square" onClick={() => handleClick(index)}>
        {board[index]}
      </button>
    );
  };

  const winner = calculateWinner(board);
  let status;
  if (winner) {
    status = `Winner: ${winner}`;
  } else if (board.every((square) => square !== null)) {
    status = "It's a draw!";
  } else {
    status = `Next player: ${xIsNext ? 'Player' : 'Computer'}`;
  }

  return (
    <div className="game">
      <div className="scoreboard">
        <h3>Player Wins: {playerWins}</h3>
        <h3>Computer Wins: {computerWins}</h3>
        <h3>Draws: {draws}</h3>
      </div>
      <div className='buttons-container'>
          <button onClick={handleReset}>Reset Score</button>
          <button onClick={handleNewGame}>New Game</button>
        </div>
      <div className="game-board">
        <div className="status">{status}</div>
        <div className="board-row">
          {renderSquare(0)}
          {renderSquare(1)}
          {renderSquare(2)}
        </div>
        <div className="board-row">
          {renderSquare(3)}
          {renderSquare(4)}
          {renderSquare(5)}
        </div>
        <div className="board-row">
          {renderSquare(6)}
          {renderSquare(7)}
          {renderSquare(8)}
        </div>
      </div>
    </div>
  );
}

export default TicTacToePVE;