import React from 'react';

import './miniGameStyle/Chess.css';

function Chess() {
    return(
        <>
        Hello from the Chess mini game
        </>
    );
};

export default Chess;