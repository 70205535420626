import React from 'react';
import MainContainer from './container/MainContainer';

function App() {
  return (
    <>
      <MainContainer />
    </>
  );
}

export default App;