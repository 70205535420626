import React, { useState } from "react";
import { generateComputerChoice } from "./logic/RPSLogic";
import "./miniGameStyle/RPS.css";

function RockPaperScissors() {
  const [userChoice, setUserChoice] = useState(null);
  const [computerChoice, setComputerChoice] = useState(null);
  const [result, setResult] = useState(null);
  const [score, setScore] = useState({ wins: 0, losses: 0, draws: 0 });

  const userClick = (newUserChoice) => {
    const newComputerChoice = generateComputerChoice();
    let newResult;
    if (newComputerChoice === newUserChoice) {
      newResult = "It's a Draw!";
      setScore({ ...score, draws: score.draws + 1 });
    } else if (
      (newComputerChoice === "rock" && newUserChoice === "paper") ||
      (newComputerChoice === "paper" && newUserChoice === "scissors") ||
      (newComputerChoice === "scissors" && newUserChoice === "rock")
    ) {
      newResult = "You WIN!";
      setScore({ ...score, wins: score.wins + 1 });
    } else {
      newResult = "You LOST!";
      setScore({ ...score, losses: score.losses + 1 });
    }

    setUserChoice(newUserChoice);
    setComputerChoice(newComputerChoice);
    setResult(newResult);
  };

  const resetScore = () => {
    setScore({ wins: 0, losses: 0, draws: 0 });
  };

return (
  <div className="container">
    <div className="scoreboard">
      <div>
        <h3>Wins: {score.wins}</h3>
      </div>
      <div>
        <h3>Losses: {score.losses}</h3>
      </div>
      <div>
        <h3>Draws: {score.draws}</h3>
      </div>
      <button onClick={resetScore}>Reset Score</button>
    </div>
    <div className="rps-box">
      <h2>Computer Choice: {computerChoice ? computerChoice : "Undecided"}</h2>
      <h2>Your Choice: {userChoice ? userChoice : "Undecided"}</h2>
      <p></p>
      <button onClick={() => userClick("rock")}>Rock</button>
      <button onClick={() => userClick("paper")}>Paper</button>
      <button onClick={() => userClick("scissors")}>Scissors</button>
      <p></p>
      <h2>Result: {result ? result : "Select an option to play"}</h2>
    </div>
  </div>
);

  
  
}

export default RockPaperScissors;
