import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import './pagesStyle/Home.css';

function Home() {

    const greet = 'Hi! My name is James.\n\nI am a former Audio Engineer turned Full Stack Software Developer.\nIn 2022 I decided to make a career change, and after spending over 10 years working as a live sound engineer and AV technician deep in some of Edinburgh`s famous Clubs and Live Music venues, I decided I needed a fresh challenge. And well... Here we are! I took the plunge in the Full Stack Software Developmentcourse with CodeClan in September 2022 for an intense 16 weeks!\nI have to say, it helped me create some pretty cool projects such as this one, and others I`ve shared on another page!\nTake a look at some of my projects and get in touch.';

    const [typedText, setTypedText] = useState("");
    const [currentCharIndex, setCurrentCharIndex] = useState(0);
    const speed = 24; // Typing speed in milliseconds
    const resetTime = 5 * 60 * 1000; // Reset time in milliseconds (5 minutes)

    useEffect(() => {
        const typingInterval = setInterval(() => {
            if (currentCharIndex < greet.length) {
                setTypedText(prevTypedText => prevTypedText + greet[currentCharIndex]);
                setCurrentCharIndex(prevIndex => prevIndex + 1);
            } else {
                clearInterval(typingInterval);
                setTimeout(() => {
                    setTypedText("");
                    setCurrentCharIndex(0);
                }, resetTime);
            }
        }, speed);

        return () => clearInterval(typingInterval);
    }, [currentCharIndex, greet, resetTime]);


    return (
        <div className="container" style={{ display: 'flex'}}>
            <div className="left-column">
                {/* Left column for languages and tools */}
                <div>
                    <h2>Languages & Tools</h2>
                    <h6>Languages</h6>
                    <ReactMarkdown>[![My Skills](https://skillicons.dev/icons?i=js,html,css,py,lua)](https://skillicons.dev)</ReactMarkdown>
                    <ReactMarkdown>[![My Skills](https://skillicons.dev/icons?i=java,postgres,bash,md,mysql)](https://skillicons.dev)</ReactMarkdown>
                    <ReactMarkdown>[![My Skills](https://skillicons.dev/icons?i=gamemakerstudio)](https://skillicons.dev)</ReactMarkdown>
                    <h6>Frameworks</h6>
                    <ReactMarkdown>[![My Skills](https://skillicons.dev/icons?i=react,electron,spring)](https://skillicons.dev)</ReactMarkdown>
                    <h6>Tools</h6>
                    <ReactMarkdown>[![My Skills](https://skillicons.dev/icons?i=npm,nodejs,git,github,obsidian)](https://skillicons.dev)</ReactMarkdown>
                    <ReactMarkdown>[![My Skills](https://skillicons.dev/icons?i=pycharm,idea,vscode,visualstudio)](https://skillicons.dev)</ReactMarkdown>
                    <h6>OS</h6>
                    <ReactMarkdown>[![My Skills](https://skillicons.dev/icons?i=windows,ubuntu,apple)](https://skillicons.dev)</ReactMarkdown>
                    {/* Add more images as needed */}
                </div>
            </div>
            <div className="right-column">
                {/* Right column for greeting container */}
                <div>
                    <h2>Welcome!</h2>
                        <p style={{ margin: 0 }}>{typedText}</p>
                </div>
            </div>
        </div>
    );
}

export default Home;
