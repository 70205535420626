import React from 'react';

import './miniGameStyle/ConnectFour.css';

function ConnectFour() {
    return(
        <>
        Hello from the Connect4 mini game
        </>
    );
};

export default ConnectFour;