import React from 'react';

import './miniGameStyle/ConnectFour.css';


function Snake() {
    return (
        <>
        Hello from the Snake Game.
        </>
    );
};

export default Snake;