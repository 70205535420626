import React from 'react';

import './miniGameStyle/ConnectFour.css';

function Pacman() {
    return(
        <>
        Hello from the Pacman mini game
        </>
    );
};

export default Pacman;