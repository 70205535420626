import React from 'react';
import ReactMarkdown from 'react-markdown';


function Card({ portfolioCard }) {
  
    return (
      <div className="card">
        {/* <img src={portfolioCard.img} alt={portfolioCard.name} className="card-img" /> */}
        <div className="card-content">
          <h2 className="card-title">{portfolioCard.name}</h2>
          <p className="card-description">{portfolioCard.description}</p>
          <p className="card-info">Live: {portfolioCard.isLive ? <img src="imgs/online.png" alt="Yes" /> : <img src="imgs/offline.png" alt="No" />}</p>
          <p><ReactMarkdown>{portfolioCard.languages}</ReactMarkdown></p>
          <a href={portfolioCard.link} className="card-link" target="_blank" rel="noopener noreferrer">View Project</a>
        </div>
      </div>
    );
  };
  
  export default Card;